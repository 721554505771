import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import MainNav from '@elements/nav'

const Header = ({ pageContext, data, globalContext }) => {
  const [scrolled, setScrolled] = useState(false)
  const localizations = globalContext.localizations
  const { mainNav, ctaLinks } = data.navData.edges[0].node
  const brand = data.brandData
  
  const handleScroll = () => {
    const offset = window.scrollY
    if (offset >= 180) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  let navClasses = [`fixed w-full top-0 z-40 -mb-32 text-sm transition-all xl:text-base`]
  if (scrolled) {
    navClasses.push('scrolled')
  }

  return (
    <header
      id="header"
      className={navClasses.join(' ')}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <MainNav
          links={mainNav}
          brand={brand}
          pageContext={{ ...pageContext, localizations }}
          ctaLinks={ctaLinks}
        />
      </motion.div>
    </header>
  )
}

export default Header
