import React from 'react'
import SVG from 'react-inlinesvg'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax } from '@bhvr/web-design-system'

import SocialLinks from '@base/elements/social-links'
import useWindowSize from '@base/utils/hooks/useWindowSize'
import Markdown from '@base/parts/markdown'
import FooterNav from '@base/elements/nav/nav-footer'
import Ratings from '@base/elements/ratings'

import palmTrees from '@img/clips/palm-trees2.svg'
import twitterIcon from '@icons/x-corp.svg'

const Footer = (props) => {
  const footerNav = props.data.footerNavData
  const footer = props.data.footerData
  const socialLinks = props.data.socialData
  const theme = props.theme
  const windowSize = useWindowSize()
  let lineBreak = ''
  let horizontalAlign = ''
  if (windowSize.width <= 991) {
    lineBreak = '1.4em'
    horizontalAlign = '50%'
  }

  return (
    <>
      <section className="relative h-auto flex flex-row justify-end items-center -mt-48 lg:-mt-48 xxxl:-mt-60 -mb-px overflow-hidden">
        <div className="w-full h-full">
          <div className="w-full h-full">
            <SVG
              onError={error => console.log(error.message)}
              cacheRequests={true}
              preProcessor={code =>
                code.replace(/fill=".*?"/g, 'fill="none"')
              }
              src={palmTrees}
              className="palmtrees-overlay absolute z-10"
            />
            <Parallax
              className="gatsby-absolute w-full h-full z-0 clip-palmtrees"
              strength={-50}
              customContentClassName="w-screen h-full"
              customContent
            >
              <StaticImage
                src='../../assets/img/content-background.jpg'
                alt=""
                placeholder="blurred"
                className="w-full h-full object-cover object-right-top min-h-keyart"
                width={3800}
              />
            </Parallax>

            <div className="w-full relative z-10 flex justify-center flex-col items-center pt-52 lg:pt-60 pb-16 lg:pb-24">
              <StaticImage
                src='../../assets/img/hookedonyou-logo-large.svg'
                alt="Hooked on You"
                placeholder="blurred"
                className="mb-16 lg:mb-20 w-86 h-auto"
              />
              <StaticImage
                src='../../assets/img/steam-logo.svg'
                alt="Steam"
                placeholder="blurred"
                className="w-44 lg:w-56 h-auto text-white mb-16"
              />
              <h5 className="mb-6 leading-none text-center w-full">
                <svg className="w-full h-20 lg:h-16">
                  <text x="50%" y="55%" textAnchor="middle">
                    <Markdown
                      children={footer.socialTitle}
                      type="svg"
                      xAlign={horizontalAlign}
                    />
                    <tspan x={horizontalAlign}> </tspan>
                    <Markdown
                      children={footer.socialTitle2}
                      type="svg"
                      xAlign={horizontalAlign}
                      yAlign={lineBreak}
                    />
                  </text>
                </svg>
              </h5>
              <a
                href="https://twitter.com/HookedOnYou"
                rel="noopener noreferrer"
                className="h-10 w-10 lg:h-16 lg:w-16 relative text-white hover:text-primary transition-all duration-300 bg-black rounded-full border-solid border-white border-2 lg:border-4 hover:border-primary flex justify-center items-center"
                target="_blank"
              >
                <SVG
                  onError={(error) => console.log(error.message)}
                  cacheRequests={true}
                  preProcessor={(code) =>
                    code.replace(/fill=".*?"/g, 'fill="currentColor"')
                  }
                  src={twitterIcon}
                  className="h-5 w-5 lg:h-7 lg:w-7"
                />
                <span className="sr-only">X</span>
              </a>
              <div className="container flex flex-col items-center mt-16 lg:mt-20">
                <Ratings
                  data={footer}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-brown text-base">
        {socialLinks.length >= 2 && (
          <div className="container flex flex-col items-center border-grayDark py-24 text-white">
            {footer.socialTitle !== '' && (
              <h3 className="mb-6 text-center text-lg font-bold">
                {footer.socialTitle}
              </h3>
            )}
            <SocialLinks social={socialLinks} />
          </div>
        )}
        <div className="relative flex flex-col justify-center bg-brown py-20 text-white">
          <div className="container px-0">
            {footerNav !== null && (
              <FooterNav
                links={footerNav}
                theme={theme.FooterNav}
              />
            )}
            <p className="text-xs tracking-wider text-center w-full">
              <span>© {new Date().getFullYear()} {footer.legalText}</span>
              <span className="block">{footer.legalText2}</span>
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
