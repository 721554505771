// Navigation Listener
export const navIntersect = (sections, headerLinks, options, navWrapper) => {
  let prevYPosition = 0
  let direction = 'up'
  const isBrowser = typeof window !== 'undefined'

  const getTargetSection = (entry) => {
    const index = sections.findIndex((section) => section == entry.target)
    if (index >= sections.length - 1) {
      return entry.target
    } else {
      return sections[index + 1]
    }
  }

  const shouldUpdate = (entry) => {
    if (direction === 'down' && !entry.isIntersecting) {
      return true
    }
    if (direction === 'up' && entry.isIntersecting) {
      return true
    }
    return false
  }

  const updateMarker = (target) => {
    const id = target.getAttribute('data-section')
    headerLinks.map((link) => {
      link.classList.remove('current')
    })
    if(id === 'welcome' || id === 'top'){
      headerLinks[1].classList.add('current')
    }
    let link = headerLinks.find((el) => {
      return el.getAttribute('data-link') === `${id}`
    })
    if (link && link !== undefined) {
      link.classList.add('current')      
    }
    if (navWrapper) {
      updateScrollableNav(link)
    }
  }

  const updateScrollableNav = (el) => {
    if (el && navWrapper) {
      navWrapper.scrollLeft = el.offsetLeft;
    }
  }

  const onIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (isBrowser) {
        if (window.pageYOffset > prevYPosition) {
          direction = 'down'
        } else {
          direction = 'up'
        }
        prevYPosition = window.pageYOffset
        const target = direction === 'down' ? getTargetSection(entry) : entry.target
        if (shouldUpdate(entry)) {
          updateMarker(target)
        }
      }
    })
  }

  const observer = new IntersectionObserver(onIntersect, options)
  sections.forEach(section => observer.observe(section))
}