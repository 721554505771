import React, { useEffect, useRef, useState } from 'react'

const Loader = ({ pageContext }) => {
  const [isLoading, setLoading] = useState(true)
  const loader = useRef(null)
  const wave = useRef(null)

  useEffect(() => {
    if (isLoading === true) {
      setTimeout(function () {
        wave.current.classList.add('animate')
      }, 500)
    }
  })

  return (
    <div
      ref={loader}
      id="pageLoader"
      className="loader fixed top-0 left-0 w-screen h-screen z-50 pointer-events-none opacity-100 transition-all duration-500"
    >
      <div className="wave-wrap">
        <svg className="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" ref={wave}>
          <path fill="#0A9E9A" fillOpacity="1" d="M0,256L30,240C60,224,120,192,180,197.3C240,203,300,245,360,250.7C420,256,480,224,540,192C600,160,660,128,720,112C780,96,840,96,900,106.7C960,117,1020,139,1080,176C1140,213,1200,267,1260,277.3C1320,288,1380,256,1410,240L1440,224L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
        </svg>
      </div>
    </div>
  )
}

export default Loader