import React from 'react'
import SVG from 'react-inlinesvg'
import { StaticImage } from 'gatsby-plugin-image'

import LocaleSwitch from '@base/elements/locale-switch'
import AnchorLink from '@base/parts/anchor-link'
import Link from '@base/parts/link'
import NavUtilsNew from '@base/elements/nav/nav-utils-new'

import Markdown from '@base/parts/markdown'
import Button from '@base/parts/button'

import stars from '@icons/stars.svg'
import chevron from '@icons/chevron.svg'
import logo from '@img/hookedonyou-logo-large.svg'
import twitterIcon from '@icons/x-corp.svg'

const MainNav = (props) => {
  const pageContext = props.pageContext
  const ctaLinks = props.ctaLinks

  return (
    <div className="nav h-full flex-shrink-0 text-white">
    
      <NavUtilsNew
        locale={pageContext.locale}
        project="hookedonyou"        
        className="pointer-events-auto relative flex h-16 flex-row items-center justify-end bg-black px-5 py-2 lg:h-[55px] lg:px-8 lg:py-4"
      />
      <nav
        id="primaryNav"
        name="Primary Navigation"
        className="main-nav sticky top-0 h-20 lg:h-44 w-full list-none justify-start xl:justify-between bg-brown flex pl-8 xl:pl-16"
      >
        <StaticImage
          src='../../assets/img/main-nav-background.jpg'
          alt=""
          placeholder="blurred"
          className="gatsby-absolute z-0 h-full w-full object-cover left-0"
          width={3800}
        />
        <div className="flex w-auto h-full items-start relative py-2">
          <AnchorLink
            to="top"
            title="Hooked on You"
            className="cursor-pointer h-full inline-flex items-center"
          >
            <SVG
              onError={error => console.log(error.message)}
              cacheRequests={true}
              src={logo}
              className="w-52 xxl:w-auto h-full absolute -left-96 lg:relative lg:left-0"
            />
            <StaticImage
              src='../../assets/img/hookedonyou-mobile-logo.png'
              alt=""
              placeholder="blurred"
              className="h-16 w-16 gatsby-hidden-desktop mr-16"
            />
          </AnchorLink>
        </div>
        <div className="nav-light justify-between overflow-x-scroll overflow-y-hidden lg:overflow-visible flex w-full pr-16 lg:pr-0">
          <ul className="relative flex h-full w-auto lg:w-full items-center justify-start lg:justify-end space-x-16 lg:space-x-4 xxxl:space-x-16 text-white uppercase font-display text-xs xxl:text-sm tracking-wider shrink-0 lg:shrink px-6 xxl:pl-0 xxl:pr-20">
            {props.links.map(navLink => (
              <li
                className="nav-item flex h-full cursor-default flex-col items-center justify-center w-auto flex-nowrap shrink-0"
                key={'header-link-' + navLink.text}
                data-link={navLink.url}
              >
                {navLink.newTab ? (
                  <Link
                    to={navLink.url}
                    newTab="true"
                    rel="noopener noreferrer"
                    title={navLink.text}
                    className="main-link inline-flex items-center py-8 lg:py-0 lg:px-8 xxxl:px-12 justify-between lg:justify-center fill-current w-full cursor-pointer relative"
                  >
                    {navLink.text}
                  </Link>
                ) : (
                  <AnchorLink
                    to={navLink.url}
                    title={navLink.text}
                    className="main-link inline-flex items-center py-8 lg:py-0 lg:px-8 xxxl:px-12 justify-between lg:justify-center fill-current w-full cursor-pointer relative"
                  >
                    <SVG
                      onError={error => console.log(error.message)}
                      cacheRequests={true}
                      preProcessor={code =>
                        code.replace(/fill=".*?"/g, 'fill="currentColor"')
                      }
                      src={stars}
                      className="absolute z-10 lg:-top-2 xl:-top-6 -right-10 lg:-right-2 xl:-right-6 w-8 xl:w-14 h-auto pointer-events-none"
                    />
                    {navLink.text}
                  </AnchorLink>
                )}
              </li>
            ))}
          </ul>
          <div className="flex h-full w-auto flex-row items-center justify-end flex-nowrap shrink-0 ml-16 lg:mx-0">
            <a
              href="https://twitter.com/HookedOnYou"
              rel="noopener noreferrer"
              className="h-10 w-10 lg:h-16 lg:w-16 relative mr-14 lg:mr-8 xxxl:mr-20 text-white hover:text-primary transition-all duration-300 bg-black rounded-full border-solid border-white border-2 lg:border-4 hover:border-primary flex justify-center items-center"
              target="_blank"
            >
              <SVG
                onError={(error) => console.log(error.message)}
                cacheRequests={true}
                preProcessor={(code) =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={twitterIcon}
                className="h-5 w-5 lg:h-7 lg:w-7"
              />
              <span className="sr-only">X</span>
            </a>
            {pageContext.localizations && (
              <li
                key="mainnav-language"
                className=" flex flex-col items-center justify-between relative lg:pr-8 xl:p-0"
              >
                <LocaleSwitch
                  pageContext={pageContext}
                  className="text-primary"
                  selectorIcon={chevron}
                />
              </li>
            )}

            {ctaLinks !== null && ctaLinks.map((ctaLink) => (
              !ctaLink.mobileOnly && (
                <>
                  <Button
                    id={ctaLink.buttonId}
                    link={ctaLink.link}
                    trigger={ctaLink.trigger}
                    icon={ctaLink.icon}
                    className={`button-nav bg-tertiary relative h-full w-80 xxxl:w-96 px-8 xxxl:px-12 ml-8 justify-end items-center font-display uppercase text-center hidden xl:flex ${props.pageContext.locale === 'fr' ? 'text-xs xxl:text-base' : 'text-sm xxl:text-md'}`}
                    pageContext={pageContext}
                    eventCategory={
                      ctaLink.eventCategory ? ctaLink.eventCategory : ''
                    }
                    eventAction={ctaLink.eventAction ? ctaLink.eventAction : ''}
                    eventType={
                      ctaLink.eventCategory && ctaLink.eventCategory === 'buy'
                        ? 'main'
                        : 'form'
                    }
                    eventLocation="main nav"
                  >
                    {ctaLink.buttonId && (
                      <span className="hidden">{ctaLink.buttonId}</span>
                    )}
                    <Markdown children={ctaLink.title} />
                    <div className="button-nav-inner" />
                  </Button>
                </>
              )
            ))}
          </div>
        </div>
      </nav >
      <div className={`nav-overlay absolute z-under h-0 w-full bg-primary/60 backdrop-blur-sm backdrop-filter transition-all duration-500 lg:shadow-md`}></div>
    </div >
  )
}

export default MainNav
