import React from 'react'
import { AnimatePresence } from 'framer-motion'

import LoaderContextProvider from '@base/utils/contexts/loader-context'

import Loader from '@elements/loader'
import Layout from '@layout/layout'

const themeStyle = require('@base/assets/styles/global.scss')
const globalStyle = require('@/assets/styles/global.scss')

// Page Layout
export const wrapPageElement = ({ element, props }) => {
  if (!props.data || !props.data.pageData) {
    return (
      <LoaderContextProvider>
        {element}
      </LoaderContextProvider>
    )
  }

  let pageTitle = ''
  let defaultLocaleTitle = ''

  if (props.data && props.data.postData) {
    pageTitle = props.data.postData.title
    defaultLocaleTitle = pageTitle
    if (props.data.postData.localizations && props.data.postData.localizations.data.length > 0) {
      defaultLocaleTitle = props.data.postData.localizations.data[0].attributes.locale === 'en' ? props.data.postData.localizations.data[0].attributes.title : pageTitle
    }
  }

  return (
    <LoaderContextProvider>
      <Layout
        data={props.data ? props.data.pageData : ''}
        globalData={props.pageContext.globalData}
        theme={props.pageContext.siteTheme}
        pageContext={{ ...props.pageContext, pageTitle, defaultLocaleTitle }}
      >
        <AnimatePresence mode='wait'>
          {element}
        </AnimatePresence>
        <Loader pageContext={props.pageContext} />
      </Layout>
    </LoaderContextProvider>
  )
}

// Scroll Update on Route change
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (document.getElementById('pageLoader')) {
    document.getElementById('pageLoader').classList.add('opacity-100')
    document.querySelector('#pageLoader .wave').classList.add('animate-reverse')
    setTimeout(function () {
      document.querySelector('#pageLoader .wave').classList.remove('animate-reverse')
      document.querySelector('#pageLoader .wave').classList.add('animate')
    }, 1000)
    window.setTimeout(() => window.scrollTo(0, 0), 600)
  }
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 600)
  } else {
    // if we used the browser's forwards or back button
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), 600)
  }
  return false
}