import React, { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'

import PageContextProvider from '@base/utils/contexts/page-context'
import FrameworkDevTools from '@base/utils/dev/dev-tools'
import CountryLegalSettings from '@base/utils/helpers/geolocalization/country-legal-settings'

import { navIntersect } from '@elements/navigation-scroll-listener'
import Header from '@layout/header'
import Footer from '@layout/footer'
import { getRatingData } from '@/content/api/rating-data'

const Layout = props => {
  if (!props.globalData) {
    return ''
  }

  let pageContext = props.pageContext
  let localizations = props.data ? props.data.localizations : []
  const { locale, slug } = pageContext

  // Nav Links listener (for anchor links)
  useEffect(() => {
    let navWrapper = document.querySelector('.nav-light')
    let rootMargin = '-25%'
    const options = {
      rootMargin: rootMargin,
      threshold: 0
    }
    let sections = ''
    let headerLinks = ''
    sections = [...document.querySelectorAll('[data-section]')]
    headerLinks = [...document.querySelectorAll('[data-link]')]
    navIntersect(sections, headerLinks, options, navWrapper)
  }, [pageContext])

  // Site Custom theme
  const headerStyles = props.theme && props.theme.header ? props.theme.header : null
  const footerStyles = props.theme && props.theme.footer ? props.theme.footer : null

  // Global data
  const brandData = props.globalData.brand.edges[0].node
  const seoData = props.globalData.seo.edges[0].node.metadata
  const gameData = props.globalData.game.edges[0].node
  const socialData = props.globalData.global.edges[0].node.socialLinks
  const ratingsData = getRatingData(props)

  // Header specific data
  const navData = props.globalData.navigation
  const mainNavData = props.globalData.navigation.edges[0].node.mainNav

  // Footer specific data
  const footerNavData = props.globalData.navigation.edges[0].node.footerNav
  const footerData = props.globalData.global.edges[0].node.footer
  const gameName = props.globalData.seo.edges[0].node.metadata.metaTitleSuffix

  return (
    <CookiesProvider>
      <PageContextProvider
        pageGating={false}
      >
        <CountryLegalSettings
          data={ratingsData}
        />
        <Header
          data={{ brandData, navData, mainNavData, gameData, socialData, seoData }}
          theme={headerStyles}
          pageContext={pageContext}
          globalContext={{ locale, slug, localizations }}
        />
        {props.children}
        <Footer
          data={{ brandData, footerNavData, gameData, socialData, footerData, gameName }}
          theme={footerStyles}
          pageContext={pageContext}
          globalContext={{ locale, slug, localizations }}
        />
      </PageContextProvider>
      <FrameworkDevTools />
    </CookiesProvider>
  )
}

export default Layout
